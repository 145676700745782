import React, { Component, useState } from "react";
import NavBar from "../nav-bar.component";
import { Form, Button } from "react-bootstrap";
import PatientService from "../../services/patient.service";
import FindPatientModal from "../modal/find-patient-modal.component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import visitHistoryService from "../../services/visit-history.service";
import PATIENT_STATUS from "../../constants/patient-status.constant";
import PAYMENT_METHOD from "../../constants/payment-method.constant";
import VISIT_STATUS from "../../constants/visit-status.constant";
import userService from "../../services/user.service";
import paymentService from "../../services/payment.service";
import BlockUI from "../shared/BlockUI/blockUI";
import axios from "axios";
import ClinicToast from "../shared/toast";
import Footer from "../footer.component";
import "../doctor/consultation/doctor.css";

// const ExampleCustomTimeInput = ({ value, onChange }) => (
//     <input
//     value={value}
//     onChange={e => onChange(e.target.value)}
//     style={{ border: "solid 1px pink" }}
//     />
// );
export default class PatientRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: 1,
      show: false,
      showToast: false,
      title: "",
      body: "",
      data: [],
      icno: "",
      name: "",
      visitDate: new Date(),
      doctorId: 0,
      payment: 1,
      blocking: false,
      bgSucces: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleDoctor = this.handleDoctor.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVisitDateChange = this.handleVisitDateChange.bind(this);
    this.convertUTCDateToLocalDate = this.convertUTCDateToLocalDate.bind(this);
    this.handleCloseToast = this.handleCloseToast.bind(this);
  }

  componentDidMount() {
    this.setState({
      blocking: true,
    });
    const doctor = userService.getDoctors();
    const payment = paymentService.getPayments();

    axios
      .all([doctor, payment])
      .then(
        axios.spread((...responses) => {
          this.setState({
            blocking: false,
          });
          const lsDocs = responses[0];
          const lsPays = responses[1];

          var docs = lsDocs.data.map((data, index) => (
            <option key={index} value={data.id}>
              {data.name}
            </option>
          ));

          this.setState({
            doctors: docs,
          });

          var pays = lsPays.data.map((data, index) => (
            <option key={index} value={data.id}>
              {data.name}
            </option>
          ));

          this.setState({
            payments: pays,
          });

          //const responseTwo = responses[1]
          //const responesThree = responses[2]
          // use/access the results
        })
      )
      .catch((errors) => {
        // react on errors.
        console.log(errors);
      });
  }
  handleShow = () => {
    this.setState({
      show: true,
      title: "Find Patient",
      data: null,
    });
  };

  handleFindPatient = (formData) => {
    var formValues = Object.assign({}, formData);

    let { name, icno } = formValues;

    this.setState({
      show: false,
      icno,
      name,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
      searchType: 1,
    });
  };

  handleAction(event) {
    this.setState({ action: event.target.value });
  }
  handlePayment(event) {
    this.setState({ payment: event.target.value });
  }
  handleDoctor(event) {
    this.setState({ doctorId: event.target.value });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleVisitDateChange(date) {
    let n = new Date();
    let h = n.getHours();
    let m = n.getMinutes();
    let s = n.getSeconds();

    let updDate = new Date(date);
    updDate.setHours(h);
    updDate.setMinutes(m);
    updDate.setSeconds(s);

    this.setState({
      visitDate: new Date(updDate),
    });
  }
  convertUTCDateToLocalDate(date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }
  handleCloseToast() {
    this.setState({
      showToast: false,
    });
  }
  handleSubmit(event) {
    event.preventDefault();

    if (this.state.name == "" && this.state.icno == "") {
      alert("Ic no and name is empty, please find patient");
      return;
    }

    var visitDate = this.state.visitDate;

    let visit = {
      patientId: this.state.icno,
      datevisit: visitDate,
      doctorId: this.state.doctorId,
      status: PATIENT_STATUS.Waiting,
      paymentmethod: this.state.payment,
      action: this.state.action,
    };

    visitHistoryService.register(visit).then(
      (result) => {
        if (result.data) {
          if (result.data.statusId == VISIT_STATUS.AlreadyRegistered) {
            //alert('This patient already registered')
            this.setState({
              showToast: true,
              message: "This patient already registered",
              bgSucces: false,
            });
          } else {
            //alert('This patient registered successfully')
            this.setState({
              showToast: true,
              message: "This patient registered successfully",
              bgSucces: true,
            });
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage,
        });
      }
    );
  }

  render() {
    return (
      <>
        <div className="menu">
          <NavBar></NavBar>
        </div>

        <div className="content">
          <h2>Register Patient</h2>
          <Form className="formClinic" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-lg-8">
                <Form.Group controlId="formIcNo">
                  <Form.Label>Identification No</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.icno}
                    name="icno"
                    readOnly
                    placeholder="Ic No"
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Button
                  style={{ marginTop: "30px" }}
                  variant="primary"
                  onClick={this.handleShow}
                >
                  Find Patient
                </Button>
              </div>
            </div>

            <FindPatientModal
              show={this.state.show}
              title={this.state.title}
              // body={this.state.body}
              // data={this.state.data}
              onClick={this.handleFindPatient}
              onHide={this.handleClose}
            />

            <Form.Group controlId="formNama">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={this.state.name}
                name="name"
                readOnly
                placeholder="Name"
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>
            <div className="row">
              <div className="col-lg-8">
                <Form.Group controlId="formAction">
                  <Form.Label>Action</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleAction}
                    name="action"
                  >
                    <option value="1">see a doctor</option>
                    <option value="2">buy medicine at counter</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group controlId="formDateVisit">
                  <Form.Label>Date Visit</Form.Label>
                  <div>
                    <DatePicker
                      selected={this.state.visitDate}
                      onChange={this.handleVisitDateChange}
                      dateFormat="dd-MM-yyyy"
                      // showTimeInput
                      // customTimeInput={<ExampleCustomTimeInput />}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8">
                <Form.Group controlId="formPayment">
                  <Form.Label>Payment</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handlePayment}
                    required
                  >
                    {this.state.payments}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group controlId="formDoctor">
                  <Form.Label>Doctor</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleDoctor}
                    required
                  >
                    <option value=""></option>
                    {this.state.doctors}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
          <BlockUI blocking={this.state.blocking}></BlockUI>

          <div className="registerToast">
            <ClinicToast
              bgSucces={this.state.bgSucces}
              show={this.state.showToast}
              content={this.state.message}
              close={this.handleCloseToast}
            ></ClinicToast>
          </div>
        </div>
        <div className="docMobile">
          <h2>Register Patient</h2>
          <Form className="formClinic" onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-lg-8">
                <Form.Group controlId="formIcNo">
                  <Form.Label>Identification No</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.icno}
                    name="icno"
                    readOnly
                    placeholder="Ic No"
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Button
                  style={{ marginTop: "30px" }}
                  variant="primary"
                  onClick={this.handleShow}
                >
                  Find Patient
                </Button>
              </div>
            </div>

            <FindPatientModal
              show={this.state.show}
              title={this.state.title}
              // body={this.state.body}
              // data={this.state.data}
              onClick={this.handleFindPatient}
              onHide={this.handleClose}
            />

            <Form.Group controlId="formNama">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={this.state.name}
                name="name"
                readOnly
                placeholder="Name"
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>
            <div className="row">
              <div className="col-lg-8">
                <Form.Group controlId="formAction">
                  <Form.Label>Action</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleAction}
                    name="action"
                  >
                    <option value="1">see a doctor</option>
                    <option value="2">buy medicine at counter</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group controlId="formDateVisit">
                  <Form.Label>Date Visit</Form.Label>
                  <div>
                    <DatePicker
                      selected={this.state.visitDate}
                      onChange={this.handleVisitDateChange}
                      dateFormat="dd-MM-yyyy"
                      // showTimeInput
                      // customTimeInput={<ExampleCustomTimeInput />}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8">
                <Form.Group controlId="formPayment">
                  <Form.Label>Payment</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handlePayment}
                    required
                  >
                    {this.state.payments}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group controlId="formDoctor">
                  <Form.Label>Doctor</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleDoctor}
                    required
                  >
                    <option value=""></option>
                    {this.state.doctors}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
          <BlockUI blocking={this.state.blocking}></BlockUI>

          <div className="registerToast">
            <ClinicToast
              bgSucces={this.state.bgSucces}
              show={this.state.showToast}
              content={this.state.message}
              close={this.handleCloseToast}
            ></ClinicToast>
          </div>
        </div>

        {/* <Footer></Footer> */}
      </>
    );
  }
}
